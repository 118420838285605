@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');

@font-face {
  font-family: 'Berlin';
  src: url('./assets/fonts/BRLNSR.TTF') format('truetype');
  font-style: normal;
  font-weight: normal;
}


.landing {
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

.f-berlin {
  background-size: cover;
  font-family: 'Berlin', sans-serif;
}

.h0 {
  font-size: 55px
}

.text-blue {
  color: #182C3F
}

.bg-blue {
  background: #182C3F
}

input {
  border: none;
  border-radius: 30px;
  padding: 11px 30px;
  width: 100%
}

header {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  background: url('./assets/img/header.jpg') center center no-repeat;
  background-size: cover;
}

.bg-green {
  background: #86A825;
}

.logoA {
  display: block;
  position: relative;
  height: 250px;
  background: url('./assets/img/logoA.png') center center no-repeat;
  background-size: contain;
}

.logoB {
  display: block;
  position: relative;
  height: 250px;
  background: url('./assets/img/logoB.png') center center no-repeat;
  background-size: contain;
}


.logoC {
  display: block;
  position: relative;
  height: 90px;
  margin: auto auto;
  background: url('./assets/img/logoC.png') center center no-repeat;
  background-size: contain;
}
.logoD {
  display: block;
  position: relative;
  height: 100px;
  background: url('./assets/img/logoA.png') center top no-repeat;
  background-size: contain;
}

.logoE {
  display: block;
  position: relative;
  height: 100px;
  background: url('./assets/img/logoB.png') center top no-repeat;
  background-size: contain;
}

.logoF {
  display: block;
  position: relative;
  height: 100px;
  background: url('./assets/img/logoC.png') center top no-repeat;
  background-size: contain;
}

.uappex {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 50px;
  margin: -20px 10px;
  background: url('./assets/img/uappex.png') center center no-repeat;
  background-size: contain;
}

.font {
  font: menu
}
.next {
  display: inline-block;
  position: absolute;
  width: 40px; 
  height: 40px; 
  margin-right: 0px;
  margin-top: -1px;
  background: url('./assets/img/next.png') center center no-repeat;
  background-size: contain;
}

.next-dark {
  display: inline-block;
  position: relative;
  width: 40px; 
  height: 40px; 
  margin-left: -44px;
  margin-top: -14px;
  top: 14px;
  background: url('./assets/img/nextDark.png') center center no-repeat;
  background-size: contain;
}

.down {
  display: block;
  position: absolute;
  bottom: 15px;
  line-height:5px
}

.sticky-button {
  display: block;
  position: fixed;
  width: 55px;
  height: 55px;
  right: 30px;
  bottom: 30px;  
  background: url('./assets/img/wtpDark.png') center center no-repeat;
  background-color: rgba(255, 255, 255, 0.9);
  background-size: contain;
  border-radius: 50px;
  z-index: 2;
}

.point {
  display: inline-block;
  position: relative;
  width: 11px;
  height: 11px;
  margin-right: 9px;
  background: url('./assets/img/point.png') center right no-repeat;
  background-size: contain;
}

.texture-right {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  right: 30px;
  top: 0;  
  background: url('./assets/img/texture.png') center right no-repeat;
  background-size: contain;
  z-index: -1;
}

.texture-left {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 30px;
  top: 0;  
  background: url('./assets/img/texture.png') center left no-repeat;
  background-size: contain;
  z-index: -1;
}

.support {
  display: block;
  position: relative;
  height: 290px;
  margin-left: -50px;
  margin-bottom: -50px;
  margin-top: 4px;
  background: url('./assets/img/img.png') center top no-repeat;
  background-size: contain;
}

.slide {
  width: 100%;
  margin-bottom: 50px;
}

.img-t {
  width: 50%;
}

.swiper-pagination-bullet-active {
  background: #86A825 !important;
}

.bg-deg {
  background: linear-gradient(90deg, rgba(134,168,37,1) 27%, rgba(32,103,142,1) 100%);
}

.rounded-50 {
  border-radius: 30px;
}

.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: black;
  z-index: 100;
  overflow: hidden;
}


.img-modal {
  width: auto;
  height: 85vh;
}

.zoom {
  transition-duration: 300ms;
}

.zoom:hover {
  transform: scale(1.1);
  transition-duration: 300ms;
}

.icon {
  display: inline-block;
  position: relative;
  height: 100px;
}

.icon-sm {
  display: inline-block;
  position: relative;
  height: 75px;
}

.animation {
  animation-duration: 1s;
  animation-name: saltar;
  animation-iteration-count: infinite;
  animation-direction:alternate;
}

@keyframes saltar {
  from {
    transform: scale(1)
  }

  to {
      transform: scale(1.2)
  }
}

/* texture none */

@media only screen and (max-width: 1200px) {
  .texture-right {
    display: none;
  }

  .texture-left {
    display: none;
  }
}

/* Movil */

@media only screen and (max-width: 600px) {

  .landing {
    font-size: 11px;
  }
  
  .h0 {
    font-size: 35px
  }

  h1 {
    font-size: 20px
  }

  h2, .h2 {
    font-size: 17px
  }

  h5 {
    font-size: 13px
  }

  h6 {
    font-size: 11px
  }

  input {
    padding: 10px 15px;
  }

  header {
    display: flex;
    height: 100vh;
    width: 100%;
    top: 0;
    align-items: center;
    background: url('./assets/img/headerMovil.jpg') center center no-repeat;
    background-size: cover;
  }

  .bg-blue-texture {
    background: url('./assets/img/textureMovil.png') center right no-repeat;
    background-size: cover;
  }
  
  .logoA {
    height: 100px;
  }

  .logoB {
    height: 100px;
    background: url('./assets/img/logoB.png') center left no-repeat;
    background-size: contain;
  }


  .logoC {
    height: 50px;
  }
  .logoD {
    height: 50px;
  }

  .logoE {
    height: 50px;
  }

  .logoF {
    height: 50px;
  }

  .uappex {
    width: 70px;
  }

  .font {
    font: menu;
    font-size: 11px;
  }

  .next {
    width: 33px; 
    height: 33px; 
    margin-top: -6px;
    margin-left: 13px;
  }

  .next-dark {
    width: 33px; 
    height: 33px; 
    margin-left: -36px;
    top: 13px;
    margin-top: -13px;
  }

  .sticky-button {
    width: 45px;
    height: 45px;
    right: 15px;
    bottom: 15px;  
  }

  .point {
    width: 9px;
    height: 9px;
    margin-right: 5px;
  }

  .texture-right {
    display: none;
  }

  .texture-left {
    display: none;
  }

  .support {
    width: 100%;
    height: 190px;
    margin-left: 0;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .slide {
    width: 100%;
    margin-bottom: 50px;
  }

  .img-t {
    width: 60px;
    border-radius: 20px;
  }

  .swiper-pagination-bullet-active {
    background: #86A825 !important;
  }

  .bg-deg {
    background: linear-gradient(90deg, rgba(134,168,37,1) 27%, rgba(32,103,142,1) 100%);
  }

  .rounded-50 {
    border-radius: 30px;
  }

  .img-modal {
    margin-top: 50%;
    width: 100%;
    height: auto;
  }

  .icon {
    height: 70px;
  }

  .icon-sm {
    height: 40px;
  }

}